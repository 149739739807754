<template>
    <div class="ToolsView ViewWrapper">
        <clv-head-meta :title="$t('Tools')"></clv-head-meta>
        <view-header>
            <h1>{{ $t('Tools') }}</h1>
        </view-header>
        <view-container>
            <div class="row mt-4 C-D-None-md-Down">
                <div class="col">
                    <input v-model="term" name="query" class="fluid-shadowed-input-1" :placeholder="$t('Type to search')" autocomplete="off"/>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12 col-md-4" v-for="(toolInfo, index) in toolListToDisplay" :key="index">
                    <div class="gk-card">
                        <div class="d-flex justify-content-between" style="min-height: 64px;">
                            <p class="mb-0 weight-5">{{ toolInfo.label }}</p>
                            <small v-if="toolInfo.extraWord">
                                <span class="badge badge-pill bg-accent1 text-white text-uppercase ml-1">{{ $t(toolInfo.extraWord) }}</span>
                            </small>
                        </div>
                        <div>
                            <div v-if="toolInfo.linkTo">
                                <router-link tag="button" class="btn btn-sm btn-secondary-alt" :to="toolInfo.linkTo">{{ $t('common_action_use' ) }}</router-link>
                            </div>
                            <div v-else>
                                <button class="btn btn-sm btn-secondary-alt" :disabled="true">{{ $t('Coming Soon') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </view-container>
    </div>
</template>

<script>
/**
 * View with platform's tools and settings.
 *
 * @future implementations:
 * - add checkbox (like category) show settings.
 * - add tags
 *
 * @author Dimitris Gkoulis
 * @createdAt 2019
 * @lastModifiedAt 16 March 2021
 */
export default {
    name: 'ToolsView',
    data () {
        return {
            term: '',
            toolList: [
                {
                    name: 'tools_email_templates',
                    tags: [
                        'content'
                    ],
                    linkTo: {
                        name: 'email-templates'
                    },
                    extraWord: 'common_service_status_beta'
                },
                {
                    name: 'tools_sender_email_addresses',
                    linkTo: {
                        name: 'sender-email-addresses'
                    },
                    extraWord: null
                },
                {
                    name: 'tools_domain_definition_person',
                    tags: [],
                    linkTo: {
                        name: 'schema-definition-edit',
                        params: {
                            id: 'person'
                        }
                    },
                    extraWord: 'common_service_status_beta'
                },
                /*
                {
                    name: 'tools_politics_add_on',
                    tags: [],
                    linkTo: {
                        name: 'generic-add-on',
                        params: {
                            addOn: 'politics'
                        }
                    },
                    extraWord: null
                },
                */
                {
                    name: 'tools_gdpr_add_on',
                    tags: [],
                    linkTo: {
                        name: 'generic-add-on',
                        params: {
                            addOn: 'gdpr'
                        }
                    },
                    extraWord: null
                },
                {
                    name: 'tools_i18n_add_on',
                    tags: [],
                    linkTo: {
                        name: 'generic-add-on',
                        params: {
                            addOn: 'i18n'
                        }
                    },
                    extraWord: null
                },
                {
                    name: 'tools_subscriptions_portal',
                    tags: [],
                    linkTo: {
                        name: 'subscriptions-portal'
                    },
                    extraWord: null
                },
                {
                    name: 'settings_tenant_account_update_information',
                    linkTo: {
                        name: 'tenant-account-update-information'
                    }
                },
                {
                    name: 'settings_branding',
                    linkTo: {
                        name: 'branding'
                    }
                },
                {
                    name: 'settings_data_privacy_and_consent',
                    linkTo: {
                        name: 'data-privacy-and-consent'
                    }
                },
                {
                    name: 'settings_quotas',
                    linkTo: {
                        name: 'quotas'
                    }
                },
                {
                    name: 'settings_credits',
                    linkTo: {
                        name: 'credits'
                    }
                }
            ]
        };
    },
    computed: {
        toolListToDisplay () {
            let query = this.term;
            if (this.query === null || this.query === '') {
                return this.query;
            }
            query = query.toLocaleLowerCase();
            return this.toolList.filter(item => item.label.toLocaleLowerCase().includes(query));
        }
    },
    beforeMount () {
        // Translate all tools labels.
        // That makes them searchable in the active language.
        this.toolList = this.toolList
            .map(function (tool) {
                tool.label = this.$i18n.t(tool.name);
                return tool;
            }.bind(this));
    }
};
</script>

<style lang="scss">
    .ToolsView {

        .gk-card {
            border-radius: 2px;
        }
    }
</style>
